import React from 'react'
import {graphql} from 'gatsby'
import Layout from './../components/layout'
import WorkSubNav from './../components/work-sub-nav'
import WorkSubHeading from './../components/work-sub-heading'
import GalleryGrid from './../components/gallery-grid'
// import {WORK_2000_PRESENT_IMAGES} from './../constants/work-2000-present-images'
import WorkIntroText from './../components/work-intro-text'
import WorkFooterText from './../components/work-footer-text'
import ScrollToTop from './../components/scroll-to-top'

export default props => {
  const {data} = props
  const prismicData = {
    limitedEditionPrintsAreAvailable: data.prismicLimitedEditionPrintsPage.data.currently_available,
    introText: data.prismicWork2000PresentPage.data.intro_text.html,
    footerText: data.prismicWork2000PresentPage.data.footer_text.html,
    pageHeading: data.prismicWork2000PresentPage.data.page_heading.text,
    images: data.prismicWork2000PresentPage.data.images,
  }

  const WORK_2000_PRESENT_IMAGES = prismicData.images.map(item => {
    return {
      title: item.image_title.text,
      fullUrl: item.image.url,
      thumbnailUrl: item.image.thumbnails.w400.url,
      orientation: item.image_orientation,
      supplementary_info: item.image_dimentions.text,
    }
  })

  return (
    <Layout pageName="work-2000-present" documentTitlePrefix={`${prismicData.pageHeading} | Work`}>
      <WorkSubNav
        currentPageName="work-2000-present"
        limitedEditionPrintsAreAvailable={prismicData.limitedEditionPrintsAreAvailable}
      />
      <WorkSubHeading headingText={prismicData.pageHeading} />
      <WorkIntroText html={prismicData.introText} />
      <GalleryGrid staticImagesFolderName="work-2000-present" imageData={WORK_2000_PRESENT_IMAGES} classModifier="work" />
      <WorkFooterText html={prismicData.footerText} />
      <ScrollToTop />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicLimitedEditionPrintsPage {
      data {
        currently_available
      }
    }
    prismicWork2000PresentPage {
      data {
        page_heading {
          text
        }
        intro_text {
          html
        }
        footer_text {
          html
        }
        images {
          image_title {
            text
          }
          image_dimentions {
            text
          }
          image {
            url(imgixParams: {q: 70, h: 1400, fit: "scale"})
            thumbnails {
              w400 {
                url(imgixParams: {q: 50})
              }
            }
          }
          image_orientation
        }
      }
    }
  }
`
